<!--
 * @Author: mulingyuer
 * @Date: 2021-04-14 17:40:26
 * @LastEditTime: 2021-05-17 17:17:20
 * @LastEditors: mulingyuer
 * @Description: 抽奖记录
 * @FilePath: \saas-admin-vue\src\modules\lottery\views\lottery-record.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page lottery-record">
    <div class="head">
      <div class="head-group-btn top-btn">
        <el-button size="small" @click="onBack">返回</el-button>
      </div>
      <lotteryRecordFilter :filter-change="onFilter" :exportData="postData" :lottery-id="id" />
    </div>
    <div class="content" v-loading="loading">
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange">
        <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
          :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" show-overflow-tooltip>
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button type="text" size="mini" :disabled="row.is_received===0"
                @click="onReceiveInfo(row.receive_info)">领取信息</el-button>
              <el-button type="text" size="mini" :disabled="row.is_received===0 || row.is_writeoff===1"
                @click="onVerify(row)">核销
              </el-button>
            </template>
            <!-- 是否中奖 -->
            <span v-else-if="th.prop==='is_winning'">{{row[th.prop]?"是":"否"}}</span>
            <!-- 其他 -->
            <span v-else>{{row[th.prop]|placeholder}}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
    <!-- 领取信息弹窗 -->
    <ReceiveInfoDialog v-model="openReceiveInfoDialog" :user-data="receiveInfoData" />
    <!-- 核销弹窗 -->
    <VerifyDialog v-model="openVerifyDialog" :data="verifyData" :complete="getLotteryRecordList" />
  </div>
</template>
<script>
import lotteryRecordFilter from "../components/lottery-record/Filter";
import Pagination from "@/base/components/Default/Pagination";
import ReceiveInfoDialog from "../components/lottery-record/ReceiveInfoDialog";
import VerifyDialog from "../components/lottery-record/VerifyDialog";
import { lotteryRecordList } from "../api/lottery-record";
export default {
  props: {
    //抽奖数据id
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      postData: {}, //提交的数据
      //表头
      thead: [
        { label: "用户ID", prop: "user_id", minWidth: 80 },
        { label: "用户名", prop: "user_name", minWidth: 100 },
        { label: "是否中奖", prop: "is_winning", minWidth: 80 },
        { label: "奖品名称", prop: "prize_name", minWidth: 100 },
        { label: "兑换码", prop: "code", minWidth: 180 },
        { label: "核销状态", prop: "writeoff_status_text", minWidth: 80 },
        {
          label: "抽奖时间",
          prop: "create_time",
          minWidth: 200,
          sort: "custom",
        },
        {
          label: "核销时间",
          prop: "writeoff_time",
          minWidth: 150,
          sort: "custom",
        },
        { label: "操作", prop: "operate", minWidth: 150, fixed: "right" },
      ],
      tbody: [], //表格数据
      pageData: {}, //api获取的抽奖记录数据
      openReceiveInfoDialog: false, //是否显示领取信息弹窗
      receiveInfoData: {}, //领取信息弹窗的数据
      openVerifyDialog: false, //是否显示核销弹窗
      verifyData: {}, //核销的数据
    };
  },
  methods: {
    //返回
    onBack() {
      this.$router.push({ name: "LotteryList" });
    },
    //获取抽奖记录列表
    getLotteryRecordList(pageData) {
      this.loading = true;
      return lotteryRecordList(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      return this.getLotteryRecordList();
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      const order_by = prop === "time_text" ? "start_time" : prop;
      Object.assign(this.postData, { order_by, is_desc }, { page: 1 });
      this.getLotteryRecordList();
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getLotteryRecordList();
    },
    //领取信息事件
    onReceiveInfo(data) {
      this.receiveInfoData = data;
      this.openReceiveInfoDialog = true;
    },
    //核销事件
    onVerify(data) {
      this.verifyData = data;
      this.openVerifyDialog = true;
    },
  },
  created() {
    this.postData.id = this.id;
    //获取抽奖记录列表
    this.getLotteryRecordList();
  },
  components: {
    lotteryRecordFilter,
    Pagination,
    ReceiveInfoDialog,
    VerifyDialog,
  },
};
</script>
<style lang="scss" scoped>
.lottery-record {
  .head {
    margin-bottom: 24px;
  }
  .content {
    padding-bottom: 20px;
  }
}
</style>
