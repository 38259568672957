<!--
 * @Author: mulingyuer
 * @Date: 2021-04-15 11:16:53
 * @LastEditTime: 2021-04-15 11:46:53
 * @LastEditors: mulingyuer
 * @Description: 核销弹窗
 * @FilePath: \saas-admin-vue\src\modules\lottery\components\lottery-record\VerifyDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="核销" :visible.sync="show" width="500px" class="dialog-vertical verify-dialog">
    <div class="content">
      <div class="top">确定领取吗？确定后无法进行修改</div>
      <div class="bottom">
        <div class="item">
          <div class="label">奖品名称：</div>
          <div class="value">{{data.prize_name|placeholder}}</div>
        </div>
        <div class="item">
          <div class="label">兑换码：</div>
          <div class="value">{{data.code|placeholder}}</div>
        </div>
        <div class="item">
          <div class="label">姓名：</div>
          <div class="value">{{receiveInfo.name|placeholder}}</div>
        </div>
        <div class="item">
          <div class="label">手机号码：</div>
          <div class="value">{{receiveInfo.phone|placeholder}}</div>
        </div>
        <div class="item">
          <div class="label">收货地址：</div>
          <div class="value">{{receiveInfo.address|placeholder}}</div>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" :loading="loading" @click="onRecordVerify">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { recordVerify } from "../../api/lottery-record";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //核销信息数据
    data: {
      type: Object,
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //核销中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
    //领奖人信息
    receiveInfo() {
      let obj = {};
      if (this.data.receive_info) {
        obj = this.data.receive_info;
      }
      return obj;
    },
  },
  methods: {
    //核销
    onRecordVerify() {
      this.loading = true;
      const postData = { id: this.data.id };
      recordVerify(postData)
        .then((res) => {
          const { msg } = res;
          this.$message.success(msg);
          this.loading = false;
          this.show = false;
          this.complete();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.verify-dialog {
  .content {
    padding-top: 10px;
    .top {
      margin-bottom: 15px;
      font-size: 14px;
      color: #333;
    }
    .bottom {
      .item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        font-size: 14px;
        & + .item {
          margin-top: 16px;
        }
        .label {
          flex-shrink: 0;
          width: 80px;
          color: #999;
          text-align: right;
        }
        .value {
          flex-grow: 1;
          color: #000;
        }
      }
    }
  }
}
</style>