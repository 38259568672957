/*
 * @Author: mulingyuer
 * @Date: 2021-04-14 17:57:42
 * @LastEditTime: 2021-04-27 16:25:07
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\lottery\api\lottery-record.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import download from "@/base/utils/download";


// 抽奖记录列表
export const lotteryRecordList = data => {
  return api({
    url: '/admin/lottery/lottery_record/index',
    method: 'post',
    data
  });
};


// 抽奖记录列表-导出
export const exportDocument = data => {
  return download({
    url: '/admin/lottery/lottery_record/document',
    method: 'post',
    data,
    download: true
  });
};


// 抽奖记录列表-核销
export const recordVerify = data => {
  return api({
    url: '/admin/lottery/lottery_record/writeOff',
    method: 'post',
    data
  });
};