<!--
 * @Author: mulingyuer
 * @Date: 2021-04-15 10:40:12
 * @LastEditTime: 2021-04-15 11:54:29
 * @LastEditors: mulingyuer
 * @Description: 领取信息弹窗
 * @FilePath: \saas-admin-vue\src\modules\lottery\components\lottery-record\ReceiveInfoDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="领取信息" :visible.sync="show" width="500px" class="dialog-vertical receive-info-dialog">
    <div class="content">
      <div class="item">
        <div class="label">姓名：</div>
        <div class="value">{{userData.name|placeholder}}</div>
      </div>
      <div class="item">
        <div class="label">手机号码：</div>
        <div class="value">{{userData.phone|placeholder}}</div>
      </div>
      <div class="item">
        <div class="label">收货地址：</div>
        <div class="value">{{userData.address|placeholder}}</div>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" size="small" @click="show = false">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //用户信息数据
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.receive-info-dialog {
  .content {
    padding-top: 10px;
    .item {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      font-size: 14px;
      & + .item {
        margin-top: 16px;
      }
      .label {
        flex-shrink: 0;
        width: 80px;
        color: #999;
        text-align: right;
      }
      .value {
        flex-grow: 1;
        color: #000;
      }
    }
  }
}
</style>