<!--
 * @Author: mulingyuer
 * @Date: 2021-04-14 18:03:29
 * @LastEditTime: 2021-09-09 10:11:41
 * @LastEditors: aleaner
 * @Description: 抽奖记录筛选
 * @FilePath: \lottery\components\lottery-record\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <layout-filter :onFilter="onFilter" :onReset="onReset" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">

      <div class="filter-item">
        <el-form-item label="是否中奖：" prop="is_winning">
          <el-select v-model="form.is_winning" placeholder="请选择是否中奖">
            <el-option v-for="(op,index) in isWinningArr" :key="op.label+''+index" :label="op.label"
              :value="op.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="核销状态：" prop="is_writeoff">
          <el-select v-model="form.is_writeoff" placeholder="请选择核销状态">
            <el-option v-for="(op,index) in isWriteoffArr" :key="op.label+''+index" :label="op.label"
              :value="op.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名：" prop="user_name">
          <el-input v-model="form.user_name" placeholder="请输入用户名"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="奖品名称：" prop="prize_name">
          <el-input v-model="form.prize_name" placeholder="请输入奖品名称"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="抽奖时间：" start-placeholder="开始日期" :start-time.sync="form.start_time"
          start-prop="start_time" end-placeholder="结束日期" :end-time.sync="form.end_time" end-prop="end_time" />
      </div>
    </el-form>
  </layout-filter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { exportDocument } from "../../api/lottery-record";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
    //抽奖的数据id
    lotteryId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      form: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        is_winning: -1, //是否中奖
        is_writeoff: -1, //核销状态
        user_name: "", //用户名称
        prize_name: "", //奖品名称
      },
      rules: {},
      //是否中奖选项数据
      isWinningArr: [
        { label: "全部", value: -1 },
        { label: "否", value: 0 },
        { label: "是", value: 1 },
      ],
      //核销状态选项数据
      isWriteoffArr: [
        { label: "全部", value: -1 },
        { label: "待领取", value: 0 },
        { label: "待核销", value: 1 },
        { label: "已核销", value: 2 },
      ],
    };
  },
  methods: {
    //筛选事件
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出事件
    onExport() {
      const postData = {
        ...this.exportData,
        ...this.form,
        id: this.lotteryId,
      };
      return exportDocument(postData).catch((err) => {
        // console.log(err);
      });
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

